import React, { useMemo, useState } from 'react'

import Button from '../components/Button'
import { MapComponent } from '../components/map/MapComponent'
import { Menu } from '../components/Menu'
import EventCreateSlideOver from '../containers/slide-overs/EventCreateSlideOver'
import { eventTypeOptions } from '../api/events.facade'
import { Checkmark } from '../components/icons'
import { useMapEvents } from '../query/events'
import { useMapVolunteers } from '../query/volunteers'
import classNames from 'classnames'
import VolunteerInfoSlideOver from '../features/volunteers/components/VolunteerInfoSlideOver'
import EventInfoSlideOver from '../features/events/components/EventsInfoSlideOver'
import { eventsToMarkers, volunteersToMarkers } from '../utils/markers'

// const defaultBounds = { neLongitude: '24.60250854492188', neLatitude: '49.89065600169742', swLongitude: '23.459930419921875', swLatitude: '49.78437034376894'}

export function MapPage() {
  const [selectedMarker, setSelectedMarker] = useState(null)
  const selectedMarkerId = selectedMarker?.id.split('/')[1] || null
  const isEventSelected = isOpenType('event')
  const isVolunteerSelected = isOpenType('volunteer')
  
    const [showEvents, setShowEvents] = useState(true)
    const [showVolunteers, setShowVolunteers] = useState(false)
    const [showOnlyAvailableVolunteers, setShowOnlyAvailableVolunteers] = useState(true)

  const [showEndedEvents, setShowEndedEvents] = useState(false)
  const [isEventCreateSlideOverOpen, setIsEventCreateSlideOverOpen] = useState(false)

    const handleFilterShowEvents = e => {
        const checked = e.target.checked
        setShowEvents(checked)
    }

    const handleFilterShowOnlyAvailableVolunteers = e => {
        const checked = e.target.checked
        setShowOnlyAvailableVolunteers(checked)
    }

    const handleFilterShowVolunteers = e => {
        const checked = e.target.checked
        setShowVolunteers(checked)
    }

    const handleFilterEndedEvents = (e) => {
        const checked = e.target.checked
        setShowEndedEvents(checked)

        const nextStatus = checked ? 'FINISHED,IN_PROGRESS,CREATED' : 'IN_PROGRESS,CREATED'
        setEventsFilter(prev => ({...prev, status: nextStatus}))
    }

    const handleFilterEventsType = type => {
        setEventsFilter(prev => ({
            ...prev,
            eventType: type
        }))
    }

    const [eventsFilter, setEventsFilter] = useState({
        eventType: null,
        status: 'IN_PROGRESS,CREATED',
    })

  const [bounds, setBounds] = useState({
    neLongitude: null,// defaultBounds.neLongitude,
    neLatitude: null,// defaultBounds.neLatitude,
    swLongitude: null,// defaultBounds.swLongitude,
    swLatitude: null,// defaultBounds.swLatitude
  })

  const eventsQuery = useMapEvents({ ...bounds, ...eventsFilter }, showEvents)
  const volunteersQuery = useMapVolunteers({ ...bounds }, showOnlyAvailableVolunteers, showVolunteers)

  const events = useMemo(() => eventsQuery?.data || [], [eventsQuery.data])
  const volunteers = useMemo(() => volunteersQuery?.data || [], [volunteersQuery.data])

  const loading = eventsQuery.isLoading || eventsQuery.isFetching || volunteersQuery.isLoading || volunteersQuery.isFetching

  const markers = useMemo(() => {
    const volunteersMarkers = showVolunteers ? volunteersToMarkers(volunteers) : []
    const eventsMarkers = showEvents ? eventsToMarkers(events) : []
    const allMarkers = [...volunteersMarkers, ...eventsMarkers]

    if (selectedMarker) {
      const selected = allMarkers.find(marker => marker.id === selectedMarker.id)

      if (selected) {
        selected.selected = true
      }
    }

    return allMarkers
  }, [selectedMarker, events, volunteers, showVolunteers, showEvents])

  function updateBounds(data) {
    setBounds(prev => ({
      ...prev,
      neLongitude: data.neLongitude,
      neLatitude: data.neLatitude,
      swLongitude: data.swLongitude,
      swLatitude: data.swLatitude
    }))
  }

  function isOpenType(type) {
    if (selectedMarker === null) return false

    // if (type === 'new' && selectedMarker.id === 'event/new') return true

    // if (selectedMarker.id !== 'event/new') {
      return selectedMarker.id.split('/')[0] === type
    // }

    // return false
  }

  return (
    <>
      <div className="mt-5 flex justify-between items-center">
        <h1 className="text-body-900 text-lg font-bold">Мапа</h1>



              <Button
                  icon="Add"
                  variant="secondary"
                  onClick={() => setIsEventCreateSlideOverOpen(true)}
              >
                  Додати подію
              </Button>
      </div>

      <div className="flex flex-wrap gap-6 items-center min-h-12 whitespace-nowrap">
          <FilterCheckbox onChange={handleFilterShowEvents} checked={showEvents} id='showEvents'>
              Події
          </FilterCheckbox>

          {showEvents && (
              <div className='flex gap-6 items-center'>
                  <div className="flex items-center gap-2">
                      {/*<span>Фільтр:</span>*/}

                      <Menu
                          selected={eventsFilter.eventType}
                          items={[{value: null, label: 'Всі'}, ...eventTypeOptions]}
                          onSelect={handleFilterEventsType}
                      />
                  </div>

                  <FilterCheckbox onChange={handleFilterEndedEvents} checked={showEndedEvents} id='showEndedEvents'>
                      Завершені
                  </FilterCheckbox>
              </div>
          )}

          <FilterCheckbox onChange={handleFilterShowVolunteers} checked={showVolunteers} id='showVolunteers'>
              Добровольців
          </FilterCheckbox>

          {showVolunteers && (
              <div className='flex gap-6 items-center'>
                  <FilterCheckbox onChange={handleFilterShowOnlyAvailableVolunteers} checked={showOnlyAvailableVolunteers} id='showOnlyAvailableVolunteers'>
                      Лише доступні
                  </FilterCheckbox>
              </div>
          )}

          <Button
              icon="Exclamation"
              variant="secondary"
              onClick={() => {
                  eventsQuery.refetch().catch()
                  volunteersQuery.refetch().catch()
              }}
          >
              Оновити
          </Button>
      </div>

        <div className="relative z-0 pt-4 flex-grow">
            {loading && (
                <div
                    className='absolute left-1/2 -translate-x-1/2 top-8 bg-white rounded-md px-2 py-1 text-xs z-[1000]'>
                    Завантаження...
                </div>
            )}

            <MapComponent
                markers={markers}
                onMarkerClick={setSelectedMarker}
            onZoomEnd={updateBounds}
            onDragEnd={updateBounds}
            onLoad={updateBounds}
        />
      </div>

      <EventCreateSlideOver
          open={isEventCreateSlideOverOpen}
          setOpen={setIsEventCreateSlideOverOpen}
      />

      <VolunteerInfoSlideOver
        id={isVolunteerSelected && selectedMarkerId}
        isOpen={isVolunteerSelected}
        toggle={() => setSelectedMarker(null)}
      />

      <EventInfoSlideOver
        id={isEventSelected && selectedMarkerId}
        isOpen={isEventSelected}
        toggle={() => setSelectedMarker(null)}
      />
    </>
  )
}

function FilterCheckbox ({ onChange, checked, id, children }) {
  return (<label className='flex items-center gap-2.5 select-none cursor-pointer'>
      <input
        type='checkbox'
        id={id}
        className='hidden opacity-0'
        onChange={onChange}
      />
      <div
        className={classNames('flex justify-center items-center border border-primary-500 rounded-md h-4 w-4', checked && 'bg-primary-500')}
      >
        {checked && (<Checkmark className='w-4 h-4 text-white'/>)}
      </div>
      {children}
    </label>)
}
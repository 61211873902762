import { keepPreviousData, useQuery } from '@tanstack/react-query'
import {
  getExistedAvailabilityVolunteers,
  getMapAvailableVolunteers,
  getMapVolunteers,
  getVolunteer,
  getVolunteers
} from '../api/volunteer.facade'

const VOLUNTEERS_KEY = 'volunteers'

export const useVolunteers = (pageable, onlyAvailable = false) => {
  return useQuery({
    queryKey: [VOLUNTEERS_KEY, { ...pageable, onlyAvailable }],
    queryFn: () =>
      onlyAvailable
        ? getExistedAvailabilityVolunteers(pageable)
        : getVolunteers(pageable),
    staleTime: 60_000 * 3,
    refetchInterval: 60_000 * 3,
    placeholderData: keepPreviousData
  })
}

export const useMapVolunteers = (
  params,
  onlyAvailable = true,
  enabled = true
) => {
  const hasBounds =
    params?.neLongitude &&
    params?.neLatitude &&
    params?.swLongitude &&
    params?.swLatitude

  return useQuery({
    queryKey: [VOLUNTEERS_KEY, { ...params, onlyAvailable }],
    queryFn: () =>
      onlyAvailable
        ? getMapAvailableVolunteers(params)
        : getMapVolunteers(params),
    staleTime: 60_000 * 3,
    refetchInterval: 60_000 * 3,
    enabled: enabled && Boolean(hasBounds),
    placeholderData: keepPreviousData
  })
}

export const useVolunteer = (id) => {
  return useQuery({
    queryKey: [VOLUNTEERS_KEY, { id }],
    queryFn: () => getVolunteer(id),
    staleTime: 60_000 * 2,
    refetchInterval: 60_000 * 2,
    enabled: Boolean(id)
  })
}

export function volunteersToMarkers(volunteers) {
  return volunteers.map((volunteer) => ({
    id: `volunteer/${volunteer?.volunteerId || volunteer?.id}`,
    type: 'rescuer', // TODO: replace with volunteer.role when implemented
    position: [
      volunteer?.latitude || volunteer?.currentLatitude,
      volunteer?.longitude || volunteer?.currentLongitude
    ],
    selected: false
  }))
}

export function eventsToMarkers(events) {
  return events.map((event) => ({
    id: `event/${event.eventId}`,
    type: 'event',
    position: [event?.latitude, event?.longitude],
    selected: false
  }))
}

import { useEffect } from 'react'
import { useMap, useMapEvents } from 'react-leaflet'
// import { useDispatch } from 'react-redux'

// import { actions } from './mapSlice'

export const EventLayer = ({ onClick, onZoomEnd, onDragEnd }) => {
  // const dispatch = useDispatch()
  const map = useMap()

  useEffect(() => {
    map.invalidateSize()
  }, [map])

  useMapEvents({
    click: (e) => {
      const { lat, lng } = e.latlng
      onClick?.({ lat, lng })
      // dispatch(actions.click({ lat, lng }))
    },
    zoomend: () => {
      const bounds = map.getBounds()

      onZoomEnd?.({
        swLatitude: bounds.getSouth(),
        swLongitude: bounds.getWest(),
        neLatitude: bounds.getNorth(),
        neLongitude: bounds.getEast()
      })
    },
    dragend: () => {
      const bounds = map.getBounds()

      onDragEnd?.({
        swLatitude: bounds.getSouth(),
        swLongitude: bounds.getWest(),
        neLatitude: bounds.getNorth(),
        neLongitude: bounds.getEast()
      })
    }
  })

  return null
}

import { toCapitalize } from './utils'

export function formatAddress(...args) {
  return (
    args
      .filter((v) => Boolean(v) && String(v).trim())
      .map((v) => {
        const string = String(v) || ''
        const splitted = [...string.split(' ')]

        return splitted.map(toCapitalize).join(' ')
      })
      .join(', ') || ''
  )
}

import * as thunks from './thunks'

export const reducers = {}

export const extraReducers = (builder) => {
  builder
    .addCase(thunks.fetchAnalyticsVolunteers.pending, (state) => {
      state.loading = true
    })
    .addCase(thunks.fetchAnalyticsVolunteers.fulfilled, (state, action) => {
      state.loading = false
      const { content, ...pageDetails } = action.payload
      state.data = content
    })
    .addCase(thunks.fetchAnalyticsVolunteers.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

  builder
    .addCase(thunks.fetchAnalyticsVolunteer.pending, (state) => {
      state.loading = true
    })
    .addCase(thunks.fetchAnalyticsVolunteer.fulfilled, (state, action) => {
      state.loading = false
      state.current = action.payload
    })
    .addCase(thunks.fetchAnalyticsVolunteer.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

  builder
    .addCase(thunks.fetchAnalyticsEvents.pending, (state) => {
      state.loading = true
    })
    .addCase(thunks.fetchAnalyticsEvents.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
    .addCase(thunks.fetchAnalyticsEvents.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
}

import { format } from 'date-fns'
import {
  EventStatusDetails,
  EventTypeDetails
} from '../../../api/events.facade'
import SlideOver from '../../../components/SlideOver'
import {
  InfoAddress,
  InfoStatus,
  InfoTable,
  InfoText
} from '../../../components/info-table'
import { useEvent } from '../../../query/events'
import InvolvedVolunteers from './InvolvedVolunteers'

function EventInfo({ event }) {
  const eventTypeText =
    EventTypeDetails[event.eventType]?.text ?? 'Невідомий тип'

  const statusColor = EventStatusDetails[event.status]?.color ?? 'gray'
  const statusText = EventStatusDetails[event.status]?.text

  return (
    <InfoTable>
      <InfoStatus
        label="Тип події"
        statusText={eventTypeText}
        statusColor="gray"
      />
      <InfoText
        label="Час виникнення"
        text={event.createdAt ? format(event.createdAt, 'dd/MM/yyyy H:mm') : ''}
      />
      <InfoAddress address={event.address} />
      <InfoStatus statusText={statusText} statusColor={statusColor} />
      <InfoText label="Опис" text={event.description} />
    </InfoTable>
  )
}

function EventHeader({ event }) {
  const eventTypeName =
    EventTypeDetails[event.eventType]?.text ?? 'Невідомий тип'
  return (
    <div className="flex flex-col bg-gradient-to-l from-[#BF4040] to-[#660000] p-14 pb-0">
      <span className="text-2xl text-primary-0 font-bold mb-2">
        {eventTypeName}
      </span>
      <span className="text-red-100 mb-3">
        {event.createdAt ? format(event.createdAt, 'dd/MM/yyyy H:mm') : ''}
      </span>

      <div className="text-white flex items-center gap-8">
        <button
          type="button"
          className='p-4 relative after:content-[""] after:absolute after:h-[3px] after:bg-white after:bottom-0 after:left-0 after:right-0'
        >
          Інформація
        </button>
      </div>
    </div>
  )
}

function EventInfoSlideOver({ isOpen, toggle, id }) {
  const { data: event } = useEvent(id)

  return (
    <SlideOver isOpen={isOpen} toggle={toggle}>
      {event && (
        <div className="flex h-full flex-col bg-white shadow-xl overflow-y-auto">
          <EventHeader event={event} />

          <div className="px-14 pt-8">
            <EventInfo event={event} />
          </div>
          <div className="px-14">
            <InvolvedVolunteers volunteers={event.volunteers} />
          </div>
        </div>
      )}
    </SlideOver>
  )
}

export default EventInfoSlideOver

import { useQuery } from '@tanstack/react-query'
import { getAnalyticsEvents } from '../api/analytics.facade'

export const ANALYTICS_KEY = 'analytics'

export const useAnalyticsEvents = (data) => {
  return useQuery({
    queryKey: [
      ANALYTICS_KEY,
      {
        startDate: data?.startDate.toString(),
        endDate: data?.endDate.toString()
      }
    ],
    queryFn: () => getAnalyticsEvents(data),
    staleTime: 60_000 * 5,
    refetchInterval: 60_000 * 5
  })
}

import axios from 'axios'
import { store } from '../redux/store'

const baseURL = 'https://volunteer-prod.ldubgd.edu.ua'

const instance = axios.create({
  baseURL,
  timeout: 10000, // Set a timeout if needed
  headers: { 'Content-Type': 'application/json' }
})

export const publicInstance = axios.create({
  baseURL,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' }
})

instance.interceptors.request.use(
  (config) => {
    const token = store.getState()?.auth?.token

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    console.log('response', response.status)

    return response
  },
  async (error) => {
    console.error('error', error.response.status)

    if (error?.response?.status === 401 || error?.response?.status === 403) {
      store.dispatch({ type: 'auth/logout' })
    }

    return Promise.reject(error)
  }
)

export default instance

import L from 'leaflet'
import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'

import { useSelector } from 'react-redux'
import { EventLayer } from './EventLayer'
import {
  EventMarker,
  MedicMarker,
  PolicemanMarker,
  RescuerMarker
} from './markers'

const RoleZoomMap = {
  ROLE_ADMIN_USER: 6,
  ROLE_ADMIN_REGION: 8
}

/**
 * @param {Object} props
 * @param {Array<{ id: number, type: string, position: Array<number>, selected: boolean }>} props.markers
 * @returns {JSX.Element}
 * @example <Map markers={[{ id: 1, type: 'event', position: [51.500, -0.09] }]} />
 * @note The `type` can be 'event', 'medic', 'policeman', or 'rescuer'.
 * @note The parent component must have its own height
 */
export const MapComponent = ({
  markers,
  center,
  zoom,
  onMarkerClick,
  onClick,
  onZoomEnd,
  onDragEnd,
  onLoad
}) => {
  const { coordinates, role } = useSelector((state) => state.auth)
  const defaultCenter = coordinates
    ? [coordinates.latitude, coordinates.longitude]
    : [49.84108232367849, 24.030532836914066]

  const defaultZoom = RoleZoomMap[role] ?? 8

  const [mapZoom] = useState(zoom ?? defaultZoom)
  const [map, setMap] = useState(null)

  useEffect(() => {
    if (map && center && mapZoom) {
      map.setView(center, mapZoom)
    }
  }, [center, map, mapZoom])

  const eventsMarkers =
    markers?.filter((marker) => marker.type === 'event') ?? []
  const volunteersMarkers =
    markers?.filter((marker) => marker.type !== 'event') ?? []

  const createClusterVolunteer = (cluster) =>
    L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: 'marker-cluster-volunteer',
      iconSize: new L.point(40, 40),
      iconAnchor: new L.point(20, 20)
    })

  const createClusterEvent = (cluster) =>
    L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: 'marker-cluster-event',
      iconSize: new L.point(40, 40),
      iconAnchor: new L.point(20, 20)
    })

  return (
    <MapContainer
      ref={setMap}
      center={center ?? defaultCenter}
      zoom={zoom ?? defaultZoom}
      minZoom={6}
      scrollWheelZoom={true}
      className="h-full"
      whenReady={(m) => {
        const bounds = m.target.getBounds()

        onLoad?.({
          swLatitude: bounds.getSouth(),
          swLongitude: bounds.getWest(),
          neLatitude: bounds.getNorth(),
          neLongitude: bounds.getEast()
        })
      }}
      // style={{ zIndex: 0 }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <EventLayer
        onClick={onClick}
        onZoomEnd={onZoomEnd}
        onDragEnd={onDragEnd}
      />

      <MarkerClusterGroup
        showCoverageOnHover={true}
        zoomToBoundsOnClick={true}
        animate={false}
        disableClusteringAtZoom={16}
        maxClusterRadius={80}
        iconCreateFunction={createClusterEvent}
      >
        {eventsMarkers.map(({ id, type, position, selected }) => (
          <EventMarker
            key={`${type}/${id}`}
            onClick={onMarkerClick}
            id={id}
            position={position}
            selected={selected}
          />
        ))}
      </MarkerClusterGroup>

      <MarkerClusterGroup
        showCoverageOnHover={true}
        zoomToBoundsOnClick={true}
        animate={false}
        disableClusteringAtZoom={16}
        maxClusterRadius={80}
        iconCreateFunction={createClusterVolunteer}
      >
        {volunteersMarkers.map(({ id, type, position, selected }) => {
          switch (type) {
            case 'medic':
              return (
                <MedicMarker
                  key={`${type}/${id}`}
                  onClick={onMarkerClick}
                  id={id}
                  position={position}
                  selected={selected}
                  zoom={mapZoom}
                />
              )
            case 'policeman':
              return (
                <PolicemanMarker
                  key={`${type}/${id}`}
                  onClick={onMarkerClick}
                  id={id}
                  position={position}
                  selected={selected}
                  zoom={mapZoom}
                />
              )
            case 'rescuer':
              return (
                <RescuerMarker
                  key={`${type}/${id}`}
                  onClick={onMarkerClick}
                  id={id}
                  position={position}
                  selected={selected}
                  zoom={mapZoom}
                />
              )
            default:
              return null
          }
        })}
      </MarkerClusterGroup>
    </MapContainer>
  )
}

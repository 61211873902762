import { NavLink } from 'react-router-dom'
import Icon from '../components/Icon'
import avatar from '../assets/avatar.png'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

const NavigationBar = () => {
  const dispatch = useDispatch()
  const { username } = useSelector((state) => state.auth)

  const handelLogout = async () => {
    dispatch({ type: 'auth/logout' })
  }

  return (
    <nav className="bg-white shadow-md">
      <div className="grid grid-cols-2 lg:grid-cols-[auto_1fr_auto] gap-5 px-8 items-center pt-6 xl:pb-6">
        <div className="flex items-center">
          <NavLink
            to="/"
            className="text-lg xl:text-2xl font-bold text-primary-400 whitespace-nowrap"
          >
            Я—ДОБРОВОЛЕЦЬ
          </NavLink>
        </div>

        <div className="order-last col-span-full xl:col-span-1 xl:order-none xl:justify-center xl:gap-10 justify-between flex gap-2">
          <MenuNavLink to="/" icon={'Map'} text={'Мапа'} />
          <MenuNavLink to="/events" icon={'Event'} text={'Події'} />
          <MenuNavLink to="/volunteers" icon={'Helmet'} text={'Добровольці'} />
          <MenuNavLink to="/analytics" icon={'Analytics'} text={'Аналітика'} />
        </div>

        <div className="flex items-center justify-end gap-4">
          <div className="hidden md:flex items-center gap-2">
            <img
              src={avatar}
              alt="Avatar"
              loading="lazy"
              className="w-10 h-10 rounded-full"
            />
            <span className="text-primary-400 text-base leading-6">
              {username}
            </span>
          </div>

          <button onClick={handelLogout}>
            <Icon name="Logout" className="w-5 h-5 text-primary-400" />
          </button>
        </div>
      </div>
    </nav>
  )
}

function MenuNavLink({ to, icon, text }) {
  const linkStyle =
    'flex items-center text-primary-400 border-b-4 ' +
    'hover:text-gray-800 hover:no-underline ' +
    'pb-6 xl:py-6 px-2 gap-2 '

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(
          linkStyle,
          isActive && 'border-primary-400',
          !isActive && 'border-transparent'
        )
      }
    >
      <div className="bg-primary-50 p-0.5 rounded">
        <Icon name={icon} className="w-5 h-5" />
      </div>
      <span className="hidden md:inline">{text}</span>
    </NavLink>
  )
}

export default NavigationBar

import * as hlr from '@headlessui/react'
import { FixedSizeList as List } from 'react-window'
import classNames from 'classnames'

import Icon from './Icon'

export function Menu({
  selected,
  items,
  onSelect,
  placeholder,
  height,
  width
}) {
  const label = selected
    ? items.find((item) => item.value === selected)?.label || '...'
    : placeholder || 'Оберіть тип'

  return (
    <hlr.Menu>
      <hlr.MenuButton className="py-2 px-4 rounded-xl border-primary-200 border group">
        <span>{label}</span>
        <Icon
          name="Select"
          className="inline-block ml-2 w-6 h-6 group-data-[active]:-scale-100"
        />
      </hlr.MenuButton>
      <hlr.Transition
        enter="transition duration-[200ms]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-200 ease-in-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <hlr.MenuItems
          anchor={{
            gap: 8,
            to: 'bottom start'
          }}
          className="grid shadow-[0px_4px_12px_0px_rgba(13,26,38,0.25)] p-4 rounded-md grid-cols-1 z-[401] bg-white min-w-[--button-width]"
        >
          {/*{items.map(item => (*/}
          {/*    <hlr.MenuItem*/}
          {/*        key={item.value}*/}
          {/*        className="border-b-4 border-transparent data-[focus]:border-inherit pb-1"*/}
          {/*    >*/}
          {/*        <button*/}
          {/*            onClick={() => onSelect(item.value)}*/}
          {/*            className="flex flex-row items-center gap-2"*/}
          {/*        >*/}
          {/*            {item.icon && (*/}
          {/*                <span className="p-0.5 bg-primary-50 rounded-md">*/}
          {/*            <Icon name={item.icon} className="w-6 h-6"/>*/}
          {/*          </span>*/}
          {/*            )}*/}
          {/*            <span>{item.label}</span>*/}
          {/*        </button>*/}
          {/*    </hlr.MenuItem>*/}
          {/*))}*/}

          <List
            height={height || items.length < 10 ? items.length * 40 : 400}
            itemCount={items.length}
            itemSize={40}
            itemData={items}
            innerElementType="ul"
            width={width || 300}
          >
            {(props) => {
              const item = props.data[props.index]

              return (
                <hlr.MenuItem key={item.value}>
                  <button
                    style={props.style}
                    onClick={() => onSelect(item.value)}
                    className={classNames(
                      'flex items-center gap-2 h-8 border-b-4 pb-1 mb-2 border-transparent data-[focus]:border-inherit whitespace-nowrap'
                    )}
                  >
                    {item.icon && (
                      <span className="p-0.5 bg-primary-50 rounded-md">
                        <Icon name={item.icon} className="w-6 h-6" />
                      </span>
                    )}
                    <span>{item.label}</span>
                  </button>
                </hlr.MenuItem>
              )
            }}
          </List>
        </hlr.MenuItems>
      </hlr.Transition>
    </hlr.Menu>
  )
}

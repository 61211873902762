import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { getAllMapEvents, getEventById } from '../api/events.facade'

const EVENTS_KEY = 'events'

export const useMapEvents = (data, enabled = true) => {
  const hasBounds =
    data?.neLongitude &&
    data?.neLatitude &&
    data?.swLongitude &&
    data?.swLatitude

  return useQuery({
    queryKey: [EVENTS_KEY, { ...data }],
    queryFn: () => getAllMapEvents(data),
    staleTime: 60_000 * 2,
    refetchInterval: 60_000 * 2,
    enabled: enabled && Boolean(hasBounds),
    placeholderData: keepPreviousData
  })
}

export const useEvent = (id) => {
  return useQuery({
    queryKey: [EVENTS_KEY, { id }],
    queryFn: () => getEventById(id),
    staleTime: 60_000 * 2,
    refetchInterval: 60_000 * 2,
    enabled: Boolean(id)
  })
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from "react-router-dom";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import DateNavigation from '../components/DateNavigation';
import LoadingOverlay from '../components/LoadingOverlay';
import { MapComponent } from '../components/map/MapComponent';
import { fetchAnalyticsEvents } from '../features/analytics/thunks';
import {Menu} from "../components/Menu";
import {
  eachMonthOfInterval,
  eachWeekOfInterval, eachYearOfInterval,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfYear,
  sub,
  formatISO, endOfDay, eachDayOfInterval, startOfDay, startOfWeek
} from "date-fns";
import {useAnalyticsEvents} from "../query/analytics";

const formatDateToKyivTimeZone = (date) => {
  const options = {
    timeZone: 'Europe/Kyiv',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };

  const formatter = new Intl.DateTimeFormat('uk-UA', options);
  const parts = formatter.formatToParts(new Date(date));

  const year = parts.find((part) => part.type === 'year').value;
  const month = parts.find((part) => part.type === 'month').value;
  const day = parts.find((part) => part.type === 'day').value;
  const hour = parts.find((part) => part.type === 'hour').value;
  const minute = parts.find((part) => part.type === 'minute').value;
  const second = parts.find((part) => part.type === 'second').value;

  return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
};

const getMonday = (date) => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  const newDate = new Date(date.setDate(diff));
  return new Date(newDate.setHours(0, 0, 0, 0));
};

const getSunday = (date) => {
  const day = date.getDay();
  const diff = date.getDate() + (7 - day);
  const newDate = new Date(date.setDate(diff));
  return new Date(newDate.setHours(23, 59, 59, 0));
};

// TODO: REFACTOR THIS
function calculateCountByDays (events = [], dateRange, range) {
  const dailyCounts = {
    active: [],
    events: [],
    volunteers: [],
  };
  const startDate = new Date(dateRange.startDate);
  const endDate = new Date(dateRange.endDate);
  let date = startDate;
  let dateString;
  let event;

  while (date <= endDate) {
    switch (range) {
      case 'DAY':
        dateString = formatDateToKyivTimeZone(date).split('T')[1];
        event = events.find(({ date }) => formatDateToKyivTimeZone(date).split('T')[1] === dateString);
        break;
      case 'WEEK':
      case 'MONTH':
        dateString = formatDateToKyivTimeZone(date).split('T')[0];
        event = events.find(({ date }) => formatDateToKyivTimeZone(date).split('T')[0] === dateString);
        break;
      case 'YEAR':
        dateString = formatDateToKyivTimeZone(date).split('-').slice(0, -1).join('-');
        event = events.find(({ date }) => formatDateToKyivTimeZone(date).split('-').slice(0, -1).join('-') === dateString);
        break;
      default:
        return;
    }

    dailyCounts.active.push({date: dateString, count: (typeof event !== 'undefined') ? event.active_events : 0});
    dailyCounts.events.push({date: dateString, count: (typeof event !== 'undefined') ? event.all_events : 0});
    dailyCounts.volunteers.push({date: dateString, count: (typeof event !== 'undefined') ? event.volunteers : 0});

    switch (range) {
      case 'DAY':
        date = new Date(date.setHours(date.getHours() + 1));
        break;
      case 'WEEK':
      case 'MONTH':
        date = new Date(date.setDate(date.getDate() + 1));
        break;
      case 'YEAR':
        date = new Date(date.setMonth(date.getMonth() + 1));
        break;
      default:
        return;
    }
  }

  return dailyCounts;
};

const dateISO = (date) => formatISO(date, {
  representation: 'date'
})

const AnalyticsPage = () => {
  const now = new Date()

  const [searchParams, setSearchParams] = useSearchParams({
    view: 'week',
    timeline: `${dateISO(startOfWeek(now, {weekStartsOn: 1}))}:${dateISO(endOfWeek(now, {weekStartsOn: 1}))}`
  })

  const [startDate, endDate] = searchParams.get('timeline')?.split(':') ?? []
  const view = searchParams.get('view')

  const {data, isLoading, error} = useAnalyticsEvents({
    range: view.toUpperCase(),
    startDate: startOfDay(startDate),
    endDate: endOfDay(endDate)
  })

  const currents = {
    events: data?.current_events_total_count?.all_events || 0,
    volunteers: data?.current_events_total_count?.volunteers || 0,
    active: data?.current_events_total_count?.active_events || 0
  }

  const previous = {
    events: data?.previous_events_total_count?.all_events || 0,
    volunteers: data?.previous_events_total_count?.volunteers || 0,
    active: data?.previous_events_total_count?.active_events || 0
  }

  const dailyCounts = calculateCountByDays(
      data?.current_events_per_range,
    { startDate: startOfDay(startDate), endDate: endOfDay(endDate) },
      view.toUpperCase()
  )

  // const [isDataFetched, setIsDataFetched] = useState(false)
  // const [dataProcessed, setDataProcessed] = useState(false)

  // useEffect(() => {
  //   if (!analytics.length) {
  //     const [startData, endDate] = searchParams.get('timeline')?.split(':') ?? []
  //
  //     console.log('fetching', startData, endDate)
  //     // dispatch(fetchAnalyticsEvents({
  //     //   range: searchParams.get('view').toUpperCase(),
  //     //   startData: startOfDay(startData),
  //     //   endDate: endOfDay(endDate)
  //     // }))
  //   }
  // }, [analytics, dispatch, searchParams]);

  const handleViewChange = (newView) => {
    // setView(newView)
    searchParams.set('view', newView)
    setSearchParams(searchParams)
  }

  // const [dateRange, setDateRange] = useState({
  //   startDate: getMonday(new Date()),
  //   endDate: getSunday(new Date())
  // })

  // const [currentEventsCount, setCurrentEventsCount] = useState({
  //   active_events: 0,
  //   all_events: 0,
  //   volunteers: 0,
  // });
  //
  // const [previousEventsCount, setPreviousEventsCount] = useState({
  //   active_events: 0,
  //   all_events: 0,
  //   volunteers: 0,
  // });

  const [currentEventsDailyCount, setCurrentEventsDailyCount] = useState({
    active_events: [],
    all_events: [],
    volunteers: [],
  });

  // const [pagable] = useState({
  //   page: 1,
  //   size: 1000000,
  //   sortBy: 'createdAt',
  //   sortOrder: 'desc',
  //   startDate: null,
  //   endDate: null,
  //   range: 'WEEK'
  // });

  // useEffect(() => {
  //   if (!data) {
  //     return
  //   }
  //
  //   setCurrentEventsCount(data.current_events_total_count);
  //   setPreviousEventsCount(data.previous_events_total_count);
  //
  // }, [data]);

  // useEffect(() => {
  //   if (!isDataFetched) {
  //     const formattedStartDate = formatDateToKyivTimeZone(dateRange.startDate);
  //     const formattedEndDate = formatDateToKyivTimeZone(dateRange.endDate);
  //     pagable.startDate = formattedStartDate;
  //     pagable.endDate = formattedEndDate;
  //     dispatch(fetchAnalyticsEvents(pagable));
  //
  //     setIsDataFetched(true);
  //   }
  // }, [dispatch, isDataFetched, dateRange]);
  //
  // useEffect(() => {
  //   if (isDataFetched && analytics.events && !loading && !dataProcessed) {
  //     setCurrentEventsCount(analytics.current_events_total_count);
  //     setPreviousEventsCount(analytics.previous_events_total_count);
  //
  //     const dailyCounts = calculateCountByDays(analytics.current_events_per_range, dateRange, pagable.range);
  //     setCurrentEventsDailyCount(dailyCounts);
  //
  //     setDataProcessed(true);
  //   }
  // }, [isDataFetched, analytics, loading, dataProcessed]);

  const renderCount = (count, previousCount, dailyCounts) => {
    const difference = count - previousCount
    const differenceText = difference >= 0 ? `+${difference}` : difference
    const differenceColor =
      difference > 0
        ? 'bg-red-50'
        : difference === 0
          ? 'bg-body-50'
          : 'bg-green-50'

    return (
      <div className="flex flex-col items-start w-full">
        <div className="flex items-center">
          <span className="text-3xl font-bold text-primary-500">{count}</span>
          <span className={`ml-2 text-sm ${differenceColor}`}>
            {differenceText}
          </span>
        </div>
        <ResponsiveContainer width="80%" height={80}>
          <AreaChart data={dailyCounts}>
            <defs>
              <linearGradient id="color_count" x1="0" y1="0" x2="0" y2="1">
                <stop offset="10%" stopColor="#1288CB" stopOpacity={0.4} />
                <stop offset="40%" stopColor="#1288CB" stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              tick={false}
            />
            <YAxis axisLine={false} tickLine={false} tick={false} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="count"
              stroke="#1288CB"
              fillOpacity={1}
              fill="url(#color_count)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    )
  }

  const viewsItems = [
    { value: 'day', label: 'День' },
    { value: 'week', label: 'Тиждень' },
    { value: 'month', label: 'Місяць' },
    { value: 'year', label: 'Рік' },
  ]

  function generateTimelineItems(view) {
    const monthNames = ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень',
      'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'];

    if (view === 'year') {
      const endOfCurrentYear = endOfYear(now);
        const start = startOfYear(sub(endOfCurrentYear, {
            years: 3,
        }))

        const years = eachYearOfInterval({
          start,
            end: endOfCurrentYear
        })

        return years.map((startDate) => {
          return {
            value: `${dateISO(startDate)}:${dateISO(endOfYear(startDate))}`,
            label: `${format(startDate, 'yyyy')}`
          }
        }).reverse()
    } else if (view === 'month') {
      const endOfCurrentMonth = endOfMonth(now);
      const start = sub(endOfCurrentMonth, {
        years: 3,
      })

      const months = eachMonthOfInterval({
        start,
        end: endOfCurrentMonth
      }).map((startDate) => {
        const monthIndex = startDate.getMonth();
        const monthName = monthNames[monthIndex];
        const end = endOfMonth(startDate);

        return {
          value: `${dateISO(startDate)}:${dateISO(end)}`,
          label: `${monthName} ${format(startDate, 'yyyy')}`
        }
      })

      return months.reverse()
    } else if (view === 'week') {
      const endOfCurrentWeek = endOfWeek(now);
      const start = sub(endOfCurrentWeek, {
        years: 1,
      })

      const weeks = eachWeekOfInterval({
        start: start,
        end: endOfCurrentWeek
      }, {
        weekStartsOn: 1
      }).map((startDate, i, arr) => {
        const end = endOfWeek(startDate, {
            weekStartsOn: 1
        })

        return {
          value: `${dateISO(startDate)}:${dateISO(end)}`,
          label: `${format(startDate, 'dd/MM')} - ${format(end, 'dd/MM')} ${format(startDate, 'yyyy')}`
        }
      })

        return weeks.reverse()
    } else if (view === 'day') {
      const endOfCurrentDate = endOfDay(now);
        const start = sub(endOfCurrentDate, {
            years: 1,
        })

        const days = eachDayOfInterval({
          start,
          end: endOfCurrentDate
        }).map(startDate => {
            const end = endOfDay(startDate)

            return {
                value: `${dateISO(startDate)}:${dateISO(end)}`,
                label: `${format(startDate, 'dd/MM/yyyy')}`
            }
        })

      return days.reverse()
    }
  }

  const timeline = generateTimelineItems(view)

  const handleTimelineClick = (value) => {
    searchParams.set('timeline', value)
    setSearchParams(searchParams)

    // const [startDate, endDate] = value.split(':')
    // const startDate = new Date(value.split(':')[0])
    // const endDate = new Date(value.split(':')[1])
    // const formattedStartDate = startOfDay(startDate) // formatDateToKyivTimeZone(startDate);
    // const formattedEndDate = endOfDay(endDate) //formatDateToKyivTimeZone(endDate);
    //
    // pagable.range = searchParams.get('view').toUpperCase();
    // pagable.startDate = formattedStartDate;
    // pagable.endDate = formattedEndDate;

    // setDataProcessed(false);
    // setDateRange({ startDate: formattedStartDate, endDate: formattedEndDate });
    // dispatch(fetchAnalyticsEvents(pagable));
  }

  const selectedTimeline = timeline.find((item) => item.value === searchParams.get('timeline'))?.value

  return (
    <div className='flex flex-col h-screen'>
      <div className='flex-none py-4'>
        <h1 className='text-body-900 text-lg font-bold mb-2'>Аналітика</h1>
        <div className='mt-10 mb-4 flex items-center gap-8'>
          <Menu
              items={viewsItems}
              onSelect={handleViewChange}
              selected={view}
              placeholder='Оберіть період'
              width={150}
            />

          <Menu
            items={timeline}
            placeholder='Оберіть період'
            onSelect={handleTimelineClick}
            selected={selectedTimeline}
            width={200}
            />

          {/*<button onClick={() => handleViewChange('day')}>День</button>*/}
          {/*<button onClick={() => handleViewChange('week')}>Тиждень</button>*/}
          {/*<button onClick={() => handleViewChange('month')}>Місяць</button>*/}
          {/*<button onClick={() => handleViewChange('year')}>Рік</button>*/}
        </div>
        {/*<DateNavigation*/}
        {/*  startDate={new Date(dateRange.startDate)}*/}
        {/*  endDate={new Date(dateRange.endDate)}*/}
        {/*  range={pagable.range}*/}
        {/*  view={view}*/}
        {/*  onDateChange={handleDateChange}*/}
        {/*/>*/}
      </div>

      <div className="flex flex-grow">
        <div className="flex-grow relative z-0">
          <MapComponent
            markers={(data?.events ?? [])
              .map((event) => ({
                id: event.id,
                type: 'event',
                position: [event.address.latitude, event.address.longitude],
                selected: false
              }))}
            className="absolute inset-0"
          />
        </div>

        <div className='w-1/3 flex flex-col space-y-1'>
          <div className='flex flex-col items-start bg-white px-4'>
            <div className='text-md font-bold text-body-900'>Активних подій</div>
            {renderCount(
              currents.active,
              previous.active,
              dailyCounts.active
            )}
          </div>
          <div className='flex flex-col items-start bg-white px-4'>
            <div className='text-md font-bold text-body-900'>Вcього подій</div>
            {renderCount(
                currents.events,
                previous.events,
                dailyCounts.events
            )}
          </div>
          <div className='flex flex-col items-start bg-white px-4'>
            <div className='text-md font-bold text-body-900'>Вcього добровольців</div>
            {renderCount(
                currents.volunteers,
                previous.volunteers,
                dailyCounts.volunteers
            )}
          </div>
        </div>
      </div>

      {isLoading && <LoadingOverlay />}
      {error && <div className="text-red-500">{error}</div>}
    </div>
  )
}

export default AnalyticsPage
